import { template as template_f80a4c5545ab48fb812cac4fbedb4acf } from "@ember/template-compiler";
const FKControlMenuContainer = template_f80a4c5545ab48fb812cac4fbedb4acf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
