import { template as template_63d8e2f24daa4ec9bdeb549057338c03 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_63d8e2f24daa4ec9bdeb549057338c03(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_63d8e2f24daa4ec9bdeb549057338c03(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_63d8e2f24daa4ec9bdeb549057338c03(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
