import { template as template_9399b3a983184916b2d0053cfff14d74 } from "@ember/template-compiler";
const SidebarSectionMessage = template_9399b3a983184916b2d0053cfff14d74(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
