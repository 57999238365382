import { template as template_5cfd59afebac40598b33a2126ce79d8d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_5cfd59afebac40598b33a2126ce79d8d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
